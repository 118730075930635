<template>
  <section class="tw-py-8 customers">
    <div
      class="md:tw-flex md:tw-justify-between tw-items-center tw-mb-8 tw-border-b tw-pb-8 md:tw-border-gray-light3"
    >
      <div>
        <h1 class="tw-text-secondary tw-text-lg tw-font-bold">
          Recent Customers
        </h1>
        <p class="tw-text-black-light">
          Customers that have used your API in the last 48 hours.
        </p>
      </div>
      <div class="settings__button tw-mt-8 md:tw-mt-0">
        <router-link :to="{ name: 'ApiClientCustomers' }">
          <button
            class="tw-h-12 tw-text-sm tw-text-secondary tw-bg-blue-bg200 tw-rounded tw-w-48 tw-font-normal"
          >
            See All Customers
          </button>
        </router-link>
      </div>
    </div>
    <div class="tw-bg-white">
      <CustomersTable
        v-if="customers && customers.length > 0"
        :tableRows="tableRows"
        :tableHeaders="tableHeaders"
        :columnNumber="1"
        :totalPage="1"
        :currentPage="customersCurrentPage"
        :column-width="150"
        :show-pagination="false"
      />
      <EmptyState v-else>
        <template v-slot:message>
          <h3 class="tw-text-sm tw-text-secondary tw-mt-5 tw-max-w-sm">
            You do not have any customers — yet
          </h3>
          <p
            class="message tw-text-center tw-my-3 tw-text-purple-light tw-max-w-sm"
          >
            Your customers will show up here when they fund, withdraw or
            purchase tokens using your API.
          </p>
        </template>
      </EmptyState>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import CustomersTable from "@/components/tables/CustomersTable";
  import EmptyState from "@/components/notification/EmptyState";
  import { formatISODate, formatISOTime } from "@/utils/helpers";
  export default {
    name: "Customers",
    created() {
      this.fetchCustomers();
    },
    data() {
      return {
        tableHeaders: [
          "Customer Name",
          "Customers Email",
          "Date of first trade",
        ],
      };
    },
    components: { CustomersTable, EmptyState },
    computed: {
      ...mapGetters("ApiClient", [
        "customersPages",
        "customersCurrentPage",
        "customers",
      ]),
      tableRows() {
        const tableRows = this.customers?.map((customer) => {
          const name = customer.name;
          const email = customer.email;
          const date = `${formatISODate(customer.createdAt)} ${formatISOTime(
            customer.createdAt
          )}`;

          return { name, email, date };
        });
        return tableRows;
      },
    },
    methods: {
      ...mapActions("ApiClient", ["getCustomers"]),
      formatISODate,
      formatISOTime,
      async fetchCustomers() {
        try {
          await this.getCustomers();
        } catch (error) {
          return error;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .customers {
    padding: 30px 68px;
  }
</style>
