<template>
  <section class="transactions tw-py-8">
    <div class="md:tw-flex md:tw-justify-between tw-items-center tw-mb-8">
      <div>
        <h1 class="tw-text-secondary tw-text-lg">API Transactions</h1>
        <p class="tw-text-black-light">
          All transactions on your API in the past 24 hours.
        </p>
      </div>
      <div class="transactions__button tw-mt-8 md:tw-mt-0">
        <router-link :to="{ name: 'ApiClientTransactions' }">
          <CustomButton title="See All Transactions" />
        </router-link>
      </div>
    </div>
    <div class="tw-bg-white tw-mb-8">
      <div
        class="table__div tw-mb-8"
        v-if="transactions && transactions.length > 0"
      >
        <TransactionTable
          :tableRows="tableRows"
          :tableHeaders="tableHeaders"
          :columnNumber="1"
          :totalPage="transactionsPages"
          :currentPage="transactionPage"
          :column-width="150"
          :show-pagination="false"
        />
      </div>
      <EmptyState v-else>
        <template v-slot:message>
          <h3 class="tw-text-sm tw-text-secondary tw-mt-5 tw-max-w-sm">
            You do not have any transaction — yet
          </h3>
          <p
            class="message tw-text-center tw-my-3 tw-text-purple-light tw-max-w-sm"
          >
            Your transactions will show up here when you fund, withdraw or
            purchase tokens.
          </p>
        </template>
      </EmptyState>
    </div>
  </section>
</template>

<script>
  import CustomButton from "@/components/general/BtnComponent";
import EmptyState from "@/components/notification/EmptyState";
import TransactionTable from "@/components/tables/TransactionTable";
import {
  formatAmountToDollar,
  formatISODate,
  formatISOTime,
  removeUnderscore,
  truncate,
} from "@/utils/helpers.js";
import { mapActions, mapGetters } from "vuex";
  export default {
    name: "ApiClientTransactions",
    data() {
      return {
        tableHeaders: ["", "Details", "Reference", "Amount", "Price", "Status"],
      };
    },
    components: {
      TransactionTable,
      CustomButton,
      EmptyState,
    },
    created() {
      this.fetchTransactions(1);
    },
    computed: {
      ...mapGetters("ApiClient", [
        "transactions",
        "transactionsPages",
        "transactionPage",
      ]),
      tableRows() {
        return this.transactions.map((transaction) => {
          let type = removeUnderscore(transaction.type);
          switch (type) {
            case "Debit Sell Wallet":
              type = "Debit Wallet";
              break;

            case "Fund Sell Wallet":
              type = "Fund Wallet";
              break;
            default:
              break;
          }

          let name = `${transaction.token.name}`;
          switch (name) {
            case "GetEquity USD Token":
              name = "USD";
              break;
            default:
              break;
          }
          let volume = transaction.volume;
          let details;
          if (type === "Plan") {
            details = truncate(`${type}: ${transaction.price} ${name}`, 30);
          } else {
            details = truncate(`${type}: ${volume} ${name}`, 30);
          }
          let amount;
          if (transaction.price) {
            amount = transaction.price * transaction.volume;
          } else {
            amount = transaction.token?.price.buy * transaction.volume;
          }
          return {
            details: `<span class="tw-font-bold">${details}</span> <br />
          <span class="tw-text-black-light tw-text-xs">${formatISODate(
            transaction.createdAt
          )}  ${formatISOTime(transaction.createdAt)}</span>`,
            reference:
              type === "Fee" ||
              type === "Debit Wallet" ||
              type === "Send" ||
              type === "Withdraw" ||
              type === "Sell" ||
              type === "Plan"
                ? `<span class="tw-text-xs tw-mb-1">${truncate(
                    transaction.reference,
                    25
                  )}</span> <br />
          <span class="tw-text-xs tw-text-error">Debit</span>`
                : `<span class="tw-text-xs tw-mb-1">${truncate(
                    transaction.reference,
                    25
                  )}</span> <br />
          <span class="tw-text-xs tw-text-success">Credit</span>`,
            amount: formatAmountToDollar(amount, 2, "USD"),
            price: transaction.price
              ? formatAmountToDollar(transaction.price, 2, "USD")
              : "N/A",
            type: `<span class="tw-hidden">${type}</span>`,
            status: transaction.status,
          };
        });
      },
    },
    methods: {
      ...mapActions("ApiClient", ["getTransactions"]),
      async fetchTransactions(page) {
        try {
          await this.getTransactions({ page, limit: 10 });
        } catch (error) {
          return error;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .transactions {
    padding: 30px 68px;
    &__button {
      max-width: 209px;
    }
  }
</style>
