<template>
  <section
    class="all-customers tw-h-screen tw-overflow-y-scroll tw-pb-6 tw-px-4 lg:tw-px-8"
  >
    <div
      class="md:tw-flex md:tw-justify-between tw-items-center tw-mb-5 tw-pb-8"
    >
      <div>
        <h1 class="tw-text-secondary tw-text-lg tw-font-bold">All Customers</h1>
        <p class="tw-text-black-light">
          You have {{ convertToLocale(totalCustomers) }}
          {{ totalCustomers > 1 ? "customers" : "customer" }}
        </p>
      </div>
      <div class="tw-mt-8 md:tw-mt-0">
        <button
          @click="$router.go(-1)"
          class="tw-h-12 tw-text-sm tw-text-gray-dark2 tw-bg-black-light3 tw-rounded tw-w-44 tw-font-normal tw-flex tw-items-center tw-justify-center tw-gap-x-3"
        >
          <img
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1649664940/getequityV2/Company-dealroom/Back-button_moomg1.svg"
            alt="back icon"
          />
          Go Back
        </button>
      </div>
    </div>
    <div class="tw-flex tw-flex-wrap tw-gap-2 tw-mt-4 md:tw-mt-0 tw-mb-5">
      <div class="tw-mb-5 md:tw-mb-0 tw-flex tw-gap-x-0 tw-items-center">
        <Search
          :showSearchField="true"
          placeholder="Search customers"
          @search-input="setValue"
        />
        <div class="tw-w-28">
          <CustomButton
            title="Search"
            :loading="loading"
            :disabled="loading || !searchValue"
            @click="findCustomer"
          />
        </div>
      </div>
      <!-- <Filter
        :filterOptions="[]"
        @filter-click="showFilterOptions = !showFilterOptions"
        :showFilterOptions="showFilterOptions"
        @filter-selected="filterCustomers"
        @mouseleave="startTimeout"
        @mouseenter="deleteTimeout"
      /> -->
    </div>
    <div class="tw-bg-white tw-mb-8">
      <div
        class="table__div tw-mb-8"
        v-if="customersArray && customersArray.length > 0"
      >
        <CustomersTable
          :tableRows="tableRows"
          :tableHeaders="tableHeaders"
          :columnNumber="1"
          :totalPage="customersPages"
          :currentPage="customersCurrentPage"
          :column-width="150"
          @go-to-page="fetchCustomers"
        />
      </div>
      <EmptyState v-else>
        <template v-slot:message>
          <h3 class="tw-text-sm tw-text-secondary tw-mt-5 tw-max-w-sm">
            You do not have any customers — yet
          </h3>
          <p
            class="message tw-text-center tw-my-3 tw-text-purple-light tw-max-w-sm"
          >
            Your customers will show up here when they fund, withdraw or
            purchase tokens using your API.
          </p>
        </template>
      </EmptyState>
    </div>
  </section>
</template>

<script>
  import CustomersTable from "@/components/tables/CustomersTable";
  import EmptyState from "@/components/notification/EmptyState";
  import CustomButton from "@/components/general/BtnComponent";
  import Filter from "@/components/general/Filter";
  import filter from "@/mixins/filter";
  import {
    formatISODate,
    formatISOTime,
    convertToLocale,
  } from "@/utils/helpers";
  import { mapActions, mapGetters } from "vuex";
  import Search from "@/components/general/Search";
  import search from "@/mixins/search";
  export default {
    name: "AllCustomers",
    data() {
      return {
        tableHeaders: [
          "Customer Name",
          "Customers Email",
          "Date of first trade",
        ],
        showFilterOptions: false,
        showSearchField: false,
        searchValue: "",
        customersArray: [],
      };
    },
    components: {
      CustomersTable,
      EmptyState,
      Search,
      CustomButton,
      Filter,
    },
    computed: {
      ...mapGetters("ApiClient", [
        "customersPages",
        "customersCurrentPage",
        "customers",
        "totalCustomers",
        "customerSearchResult",
        "loading",
      ]),
      tableRows() {
        const tableRows = this.customersArray?.map((customer) => {
          const name = customer.name;
          const email = customer.email;
          const date = `${formatISODate(customer.createdAt)} ${formatISOTime(
            customer.createdAt
          )}`;

          return { name, email, date };
        });
        return tableRows;
      },
    },
    watch: {
      customerSearchResult(value) {
        this.customersArray = value;
      },
      customers(val) {
        this.customersArray = val;
      },
      searchValue(val) {
        if (!val) {
          this.customersArray = this.customers;
        }
      },
    },
    created() {
      this.fetchCustomers(1);
    },
    mounted() {
      this.customersArray = this.customers;
    },
    mixins: [search, filter],
    methods: {
      ...mapActions("ApiClient", ["getCustomers", "searchCustomers"]),
      formatISODate,
      formatISOTime,
      convertToLocale,
      async fetchCustomers(page) {
        let query = { page, limit: 10 };
        try {
          await this.getCustomers(query);
        } catch (error) {
          return error;
        }
      },
      async findCustomer() {
        const name = this.searchValue;
        try {
          await this.searchCustomers(name);
        } catch (error) {
          return error;
        }
      },
      filterCustomers({ value, type }) {
        if (value && value !== "All") {
          const customers = this.customers;
          const filterCustomers = customers.filter((txn) => {
            if (type === "reference") {
              return txn[type].toLowerCase().includes(value);
            } else if (type === "updatedAt") {
              return txn[type].includes(value.toISOString().split("T")[0]);
            } else {
              return txn[type] === value;
            }
          });
          this.customersArray = filterCustomers;
        } else {
          this.customersArray = this.customers;
        }
      },

      setValue(name) {
        this.searchValue = name.value;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table__div {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }
</style>
