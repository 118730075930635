<template>
  <section
    class="api-client tw-h-screen tw-overflow-y-scroll tw-pb-6 tw-px-4 lg:tw-px-8"
  >
    <h1 class="tw-text-2xl tw-font-bold tw-pb-1">API Client</h1>
    <section
      class="tw-flex tw-overflow-x-scroll tw-gap-x-4 tw-mt-6 api-client-card__section"
    >
      <ApiClientCard
        title="Customers"
        :value="totalCustomers"
        valueType="Number"
      />
      <ApiClientCard
        title="Transaction Volume"
        :value="totalVolume"
        valueType="Money"
      />
    </section>
    <section class="tw-bg-white tw-mt-8 tw-rounded tw-pb-8">
      <div
        class="md:tw-border-b tw-border-gray-light3 md:tw-flex tw-justify-between md:tw-overflow-x-scroll md:tw-w-full tw-items-center api-client__div"
      >
        <nav class="api-client__nav tw-flex tw-h-14 tw-overflow-x-scroll">
          <button
            class="api-client-nav__button tw-text-gray-dark3 hover:tw-bg-white hover:tw-text-gray-dark2 hover:tw-border-b  hover:tw-border-blue-light2"
            :class="{
              'tw-text-secondary tw-border-b-2 tw-border-primary':
                activeMenu === menu.value,
            }"
            v-for="(menu, index) in apiMenu"
            :key="index"
            @click="activeMenu = menu.value"
          >
            {{ menu.name }}
          </button>
        </nav>
        <div
          class="tw-mt-4 md:tw-mt-0 tw-ml-16 tw-flex tw-gap-4 md:tw-mr-16 tw-w-60 lg:tw-w-50 tw-justify-between lg:tw-justify-end md:tw-items-center"
        >
          <ToggleButton
            @value-selected="checkToggle"
            :reset="resetToggle"
            :value="live"
          />
          <p class="tw-text-secondary">
            {{ envModelabel }}
          </p>
        </div>
      </div>
      <section>
        <KeepAlive>
          <component :is="activeMenu" />
        </KeepAlive>
      </section>
    </section>
  </section>
</template>

<script>
  import ApiClientCard from "@/components/apiClient/ApiClientCard";
import Customers from "@/components/apiClient/Customers";
import Settings from "@/components/apiClient/Settings";
import Transactions from "@/components/apiClient/Transactions";
import ToggleButton from "@/components/general/ToggleButton";
import { getItem } from "@/utils/storage.js";
import { mapActions, mapGetters } from "vuex";
  export default {
    name: "ApiClient",
    components: {
      ApiClientCard,
      ToggleButton,
      Settings,
      Customers,
      Transactions,
    },
    data() {
      return {
        apiMenu: [
          {
            name: "API Settings",
            value: "Settings",
          },
          {
            name: "Customers ",
            value: "Customers",
          },
          {
            name: "Transactions",
            value: "Transactions",
          },
        ],
        resetToggle: false,
        activeMenu: "Settings",
      };
    },
    computed: {
      ...mapGetters("organisationModule", ["organisationDetails"]),
      ...mapGetters("ApiClient", ["totalCustomers", "totalTransactionVolume"]),
      totalVolume() {
        return this.totalTransactionVolume &&
          this.totalTransactionVolume.length > 0
          ? this.totalTransactionVolume[0].totalVolume * 10
          : 0;
      },
      envModelabel() {
        let label = null;
        switch (this.live) {
          case true:
            label = "Switch to Test Mode";
            break;
          case false:
            label = "Switch to Live Mode";
            break;
        }
        return label;
      },
      live() {
        return this.organisationDetails.live;
      },
    },
    created() {
      this.fetchInfo();
    },

    methods: {
      ...mapActions("ApiClient", [
        "getCustomers",
        "toggleEnvironment",
        "getTotalTransactionVolume",
        "getApikey"
      ]),
      ...mapActions("organisationModule", ["getOrganisationProfile"]),
      ...mapActions(["showToast"]),
      async fetchInfo() {
        const orgInfo = getItem("org_details");
        const orgId = orgInfo._id;
        try {
          await Promise.all([
            this.getCustomers(),
            this.getOrganisationProfile(orgId),
            this.getTotalTransactionVolume(),
            this.getApikey(),
          ]);
        } catch (error) {
          return error;
        }
      },
      checkToggle(val) {
        if (val !== this.live) {
          this.toggleLiveMode(val);
        }
      },
      async toggleLiveMode(value) {
        try {
          const response = await this.toggleEnvironment({
            live: value,
          });
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.fetchInfo();
        } catch (error) {
          setTimeout(() => {
            this.resetToggle = false;
          }, 2000);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .api-client {
    &-card__section {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__div {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__nav {
      gap: 60px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &-nav__button {
      &:first-of-type {
        margin-left: 68px;
      }
      min-width: 90px;
    }
  }
</style>
