<template>
  <div
    class="tw-rounded tw-flex tw-bg-white api-client__card tw-py-5 tw-w-full tw-gap-x-4"
  >
    <div
      class="tw-rounded-full tw-w-14 tw-h-14 tw-bg-black-light3 tw-flex tw-items-center tw-justify-center"
    >
      <img
        v-if="title === 'Customers'"
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1649070332/getequityV2/Company-dealroom/people-svgrepo-com_1_adnwsn.svg"
        :alt="title"
      />
      <img
        v-if="title === 'Transaction Volume'"
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1649074572/getequityV2/Company-dealroom/money-svgrepo-com_1_ayn5qg.svg  "
        :alt="title"
      />
    </div>
    <div>
      <p class="tw-text-gray-dark3">{{ title }}</p>
      <p
        class="tw-text-secondary tw-text-xl tw-font-CircularStd-medium tw-font-medium"
      >
        {{ getFormatType(value) }}
      </p>
    </div>
  </div>
</template>

<script>
  import { convertToLocale, formatAmountToDollar } from "@/utils/helpers";
  export default {
    name: "ApiClientCard",
    props: {
      title: {
        type: String,
        required: true,
      },
      value: {
        type: Number,
        required: true,
      },
      valueType: {
        type: String,
        required: true,
      },
    },
    mounted() {
      this.getFormatType(this.value);
    },
    methods: {
      convertToLocale,
      formatAmountToDollar,
      getFormatType(value) {
        let formattedValue = null;
        switch (this.valueType) {
          case "Number":
            formattedValue = this.convertToLocale(value);
            break;
          case "Money":
            formattedValue = this.formatAmountToDollar(value, 2, "USD");
            break;
        }
        return formattedValue;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .api-client {
    &__card {
      @media (max-width: 700px) {
        min-width: 100%;
      }
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
      padding-left: 68px;
    }
  }
</style>
