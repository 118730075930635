<template>
  <section class="tw-pb-8" v-if="tableRows && tableRows.length > 0">
    <div class="table__div tw-mb-10">
      <table id="customers-table">
        <thead class="header">
          <tr class="header-row">
            <th
              class="header-data tw-font-normal tw-py-6"
              :class="{
                'header-data--padding-left':
                  $route.name === 'ApiClientCustomers',
              }"
              ref="tableHead"
              :style="gridStyling"
            >
              <div
                class="tw-justify-self-start tw-text-sm tw-text-black-light"
                :class="{
                  'tw-hidden': header === '_id',
                  'tw-mx-auto': header === 'Action',
                }"
                v-for="(header, index) in tableHeaders"
                :key="'header' + index"
              >
                {{ header }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr class="body-row">
            <td class="body-data" ref="tableRow">
              <div
                class="data tw-py-6 tw-border-gray-dark hover:tw-bg-gray-lightest"
                :class="{
                  'data--padding-left': $route.name === 'ApiClientCustomers',
                }"
                v-for="customer in tableRows"
                :key="'row' + customer.email"
                :style="gridStyling"
              >
                <div
                  v-for="(detail, key, index) in customer"
                  :key="index"
                  class="tw-self-center"
                >
                  <p v-html="detail" class="tw-text-sm tw-text-black"></p>
                </div>
                <router-link
                  :to="{
                    name: 'ApiClientCustomerTransaction',
                    params: { email: customer.email },
                  }"
                >
                  <button
                    class="tw-text-sm tw-text-primary hover:tw-bg-transparent hover:tw-text-primary"
                  >
                    See Details
                  </button>
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tw-flex tw-justify-end tw-mr-8 tw-h-12">
      <Pagination
        v-if="showPagination"
        :totalPage="totalPage"
        :currentPage="currentPage"
        :maxVisible="9"
        @pageChange="gotoPage"
      />
    </div>
  </section>
</template>

<script>
  import Pagination from "@/components/navigation/Pagination";
  export default {
    name: "CustomersTable",

    components: {
      Pagination,
    },

    props: {
      tableHeaders: { type: Array, default: () => [], required: true },
      tableRows: { type: Array, default: () => [], required: true },
      columnNumber: { type: Number, default: () => 0, required: true },
      columnWidth: { type: Number, default: () => 0, required: true },
      totalPage: { type: Number, default: () => 0, required: true },
      currentPage: { type: Number, default: () => 0, required: true },
      showPagination: {
        type: Boolean,
        required: false,
        default: () => true,
      },
    },

    emits: ["go-to-page"],

    data() {
      return {
        rows: [],
        loading: false,
        colunmSize: "",
      };
    },
    computed: {
      gridStyling() {
        return {
          "grid-template-columns": `${230}px ${230}px ${230}px ${230}px repeat(${
            this.columnNumber
          }, ${this.columnWidth}px)`,
        };
      },
    },

    methods: {
      gotoPage(page) {
        this.$emit("go-to-page", page);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table__div {
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  #customers-table {
    min-width: 100%;
    width: max-content;
    overflow-x: scroll;
    @media (min-width: 1280px) {
      width: 100%;
    }
    @include fadeIn;
    .header {
      .header-row {
        border-bottom: 1px solid #e1e6f0;
        background-color: #fff;
        .header-data {
          display: grid;
          column-gap: 2rem;
          color: #323e67;
          font-size: 14px;
          letter-spacing: -0.12px;
          &--padding-left {
            padding-left: 62px;
          }
          @media (min-width: 1280px) {
            grid-auto-flow: row;
          }
        }
      }
    }
    .table-body {
      .body-row {
        border-bottom: 1px solid #e1e6f0;
        background-color: #fff;
        .body-data {
          .data {
            display: grid;
            column-gap: 2rem;
            font-size: 0.7rem;
            border-bottom: 1px solid #e1e6f0;
            &--padding-left {
              padding-left: 62px;
            }
            .body-button {
              border-radius: 50%;
              border: 0;
              &:hover {
                background-color: transparent;
                box-shadow: 2px 2px 7px 2px #f0f4fd;
              }
            }
          }
        }
      }
    }

    .action {
      &__div {
        background: #ffffff;
        box-shadow: 0px 2px 30px rgba(19, 38, 83, 0.06);
        border-radius: 4px;
        width: 200px;
        position: absolute;
        right: 50px;
        top: 30px;
        z-index: 2;
        height: max-content;
        box-sizing: border-box;
      }
      &__list {
        height: 56px;
        display: flex;
        cursor: pointer;
        align-items: center;
        width: 100%;
      }
    }
    // Added with javascript
    .signed,
    .pending,
    .rejected,
    .terminated {
      text-align: center;
      padding: 0.5rem;
      border-radius: 5px;
    }
    .signed {
      color: $success;
      background-color: $success-light;
    }
    .pending {
      color: $warning;
      background-color: $warning-light;
    }
    .rejected {
      color: $error;
      background-color: $error-light;
    }
    .terminated {
      color: $brand-primary;
      background-color: $brand-primary-light;
    }
  }

  .progress__div {
    background-image: url("https://res.cloudinary.com/djalafcj9/image/upload/v1642982703/getequityV2/investor/Mask_Group_tc5wk1.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
</style>
