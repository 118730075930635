<template>
  <section class="tw-py-8 settings">
    <div class="md:tw-flex md:tw-justify-between tw-items-center tw-mb-8">
      <div>
        <h1 class="tw-text-secondary tw-text-lg">Test API Keys</h1>
        <p class="tw-text-black-light">Copy and generate test API keys here.</p>
      </div>
      <div class="settings__button tw-mt-8 md:tw-mt-0">
        <CustomButton
          title="+ Generate new  API Key"
          @click="createApiKey"
          :disabled="loading"
          :loading="loading && loadingButton === 'apiKey'"
        />
      </div>
    </div>
    <div
      class="md:tw-flex tw-gap-x-5 tw-border-b tw-border-blue-light3 tw-pb-16"
    >
      <div class="settings-key__div tw-w-full md:tw-overflow-y-hidden tw-flex">
        <div
          class="tw-h-12 tw-border-r tw-border-blue-light4 tw-w-44 tw-flex tw-items-center tw-justify-center"
          v-if="apiSecret"
        >
          <p class="tw-text-sm tw-text-secondary">Secret Key</p>
        </div>
        <div
          class="tw-h-12 tw-flex tw-items-center tw-justify-center tw-flex-grow"
        >
          <!-- <p class="tw-text-secondary">{{ apiSecret }}</p> -->
          <input
            class="tw-text-secondary tw-block tw-border-0 tw-w-full tw-text-center"
            type="text"
            name="secretKey"
            id="secretKey"
            ref="secretKey"
            readonly
            v-model="apiSecret"
            @focus="$event.target.select()"
          />
        </div>
      </div>
      <button
        class="tw-h-12 tw-text-black-light3 tw-bg-secondary tw-w-16 tw-rounded"
        @click="copyKey"
      >
        Copy
      </button>
    </div>
    <div class="tw-pt-16">
      <h1 class="tw-text-secondary tw-text-lg">Web Hooks</h1>
      <p class="tw-text-black-light">Enter webhook URL here.</p>
      <form @submit.prevent="performWebhookAction" class="tw-mt-12">
        <label for="webhook" class="tw-mb-1 tw-text-gray-dark3"> URL</label>
        <input
          type="text"
          class="placeholder:tw-text-gray-light4 placeholder:tw-text-sm tw-h-14 tw-w-full tw-rounded tw-border-gray-darkest tw-pl-4"
          placeholder="Enter URL"
          name="url"
          v-model="url"
          id="webhook"
        />
        <div class="tw-w-56 tw-mt-8 tw-ml-auto">
          <CustomButton
            :title="webhookButtonText"
            :disabled="!url || url.length === 0 || loading"
            :loading="loading && loadingButton === 'webhook'"
          />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
  import CustomButton from "@/components/general/BtnComponent";
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: "ApiSettings",
    data() {
      return {
        url: null,
        loadingButton: "",
      };
    },
    components: {
      CustomButton,
    },
    created() {
      this.fetchSettingsInfo();
    },
    watch: {
      webhookUrl(val) {
        this.url = val;
      },
    },
    mounted() {
      this.url = this.webhookUrl;
    },
    computed: {
      ...mapGetters("ApiClient", ["webhook", "apiKeyInfo", "loading"]),
      apiSecret() {
        return this.apiKeyInfo?.secret;
      },
      webhookUrl() {
        return this.webhook?.url;
      },
      webhookButtonText() {
        let text = null;
        switch (this.webhook) {
          case null:
            text = "Create Webhook";
            break;

          default:
            text = "Save Changes";
            break;
        }
        return text;
      },
    },
    methods: {
      ...mapActions("ApiClient", [
        "getApikey",
        "getApiWebHook",
        "generateApikey",
        "addWebHook",
        "updateWebhook",
      ]),
      ...mapActions(["showToast"]),
      async fetchSettingsInfo() {
        try {
          await Promise.all([this.getApikey(), this.getApiWebHook()]);
        } catch (error) {
          return error;
        }
      },
      async createApiKey() {
        this.loadingButton = "apiKey";
        try {
          const response = await this.generateApikey();
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
        } catch (error) {
          return error;
        }
      },
      copyKey() {
        this.$refs.secretKey.focus();
        document.execCommand("copy");
        this.showToast({
          description: "Secret key copied",
          display: true,
          type: "success",
        });
      },
      performWebhookAction() {
        this.loadingButton = "webhook";
        switch (this.webhook) {
          case null:
            this.createWebhook();
            break;
          default:
            this.changeWebhook();
            break;
        }
      },
      async createWebhook() {
        try {
          const response = await this.addWebHook({ url: this.url });
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
        } catch (error) {
          return error;
        }
      },
      async changeWebhook() {
        try {
          const response = await this.updateWebhook({ url: this.url });
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
        } catch (error) {
          return error;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .settings {
    padding: 30px 68px;
    &__button {
      max-width: 209px;
    }
    &-key__div {
      background: #e6edff;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      height: 48px;
    }
  }
</style>
