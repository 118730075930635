<template>
  <section
    class="lg:tw-flex tw-gap-x-5 tw-h-screen tw-overflow-y-scroll tw-pb-6 tw-px-4 lg:tw-px-8"
  >
    <div
      class="tw-bg-white tw-rounded tw-py-6 customer__div tw-w-full tw-mb-8 lg:tw-mb-0"
    >
      <div class="tw-py-5 tw-border-black-light3">
        <p class="tw-text-sm tw-text-gray-dark3 tw-mb-1 tw-ml-10">Name</p>
        <p class="tw-text-black-dark tw-font-normal tw-ml-10">
          {{ customerInfo?.name }}
        </p>
      </div>
      <div class="tw-py-5 tw-border-t tw-border-black-light3">
        <p class="tw-text-sm tw-text-gray-dark3 tw-mb-1 tw-ml-10">Email</p>
        <p class="tw-text-black-dark tw-font-normal tw-ml-10">
          {{ customerInfo?.email }}
        </p>
      </div>
      <div class="tw-py-5 tw-border-t tw-border-black-light3">
        <p class="tw-text-sm tw-text-gray-dark3 tw-mb-1 tw-ml-10">
          Total number of transactions
        </p>
        <p class="tw-text-black-dark tw-font-normal tw-ml-10">
          {{ totalCustomerTransactions }}
        </p>
      </div>
    </div>
    <div
      class="tw-bg-white tw-mb-8 transactions__div tw-rounded tw-pt-4 tw-overflow-x-scroll tw-flex-grow"
    >
      <h1 class="tw-font-normal tw-ml-10 tw-text-black-dark tw-text-lg tw-mb-3">
        {{ customerInfo?.name }} Transactions
      </h1>
      <div
        class="table__div tw-mb-8"
        v-if="customerTransactions && customerTransactions.length > 0"
      >
        <CustomerTransactionTable
          :tableRows="tableRows"
          :tableHeaders="tableHeaders"
          :columnNumber="1"
          :totalPage="customerTransactionsPages"
          :currentPage="customerTransactionPage"
          :column-width="150"
          @go-to-page="fetchCustomerTransactions"
        />
      </div>
      <EmptyState v-else>
        <template v-slot:message>
          <h3 class="tw-text-sm tw-text-secondary tw-mt-5 tw-max-w-sm">
            You do not have any customers — yet
          </h3>
          <p
            class="message tw-text-center tw-my-3 tw-text-purple-light tw-max-w-sm"
          >
            Your customers will show up here when they fund, withdraw or
            purchase tokens using your API.
          </p>
        </template>
      </EmptyState>
    </div>
  </section>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import CustomerTransactionTable from "@/components/tables/CustomerTransactionTable";
  import EmptyState from "@/components/notification/EmptyState";
  import {
    formatISODate,
    formatISOTime,
    formatAmountToDollar,
    convertToLocale,
  } from "@/utils/helpers";
  export default {
    name: "CustomerTransactions",
    data() {
      return {
        tableHeaders: [
          "",
          "Token Name",
          "Amount",
          "Price",
          "Total",
          "Date & Time",
          "Status",
        ],
      };
    },
    components: {
      CustomerTransactionTable,
      EmptyState,
    },
    computed: {
      ...mapGetters("ApiClient", [
        "customerTransactions",
        "customerTransactionsPages",
        "customerTransactionPage",
        "customers",
        "totalCustomerTransactions",
      ]),
      customerInfo() {
        let customer = this.customers.find(
          (customer) => customer.email === this.$route.params.email
        );
        return customer;
      },
      tableRows() {
        const tableRows = this.customerTransactions?.map((transaction) => {
          const name = transaction.token.name;
          const amount = this.convertToLocale(transaction.volume);
          const price = this.formatAmountToDollar(transaction.price, 2, "USD");
          const total = transaction.price * transaction.price;
          const date = `${formatISODate(transaction.createdAt)} ${formatISOTime(
            transaction.createdAt
          )}`;
          const status = transaction.status;
          const image = transaction.token.image;

          return { image, name, amount, price, total, date, status };
        });
        return tableRows;
      },
    },
    created() {
      this.fetchCustomerTransactions(1);
    },
    methods: {
      ...mapActions("ApiClient", ["getCustomerTransactions", "getCustomers"]),
      formatISODate,
      formatISOTime,
      formatAmountToDollar,
      convertToLocale,
      async fetchCustomerTransactions(page) {
        const email = this.$route.params.email;
        let query = { page, limit: 10, email };
        try {
          await [this.getCustomerTransactions(query), this.getCustomers()];
        } catch (error) {
          return error;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .transactions__div {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
    @media screen and (min-width: 1024px) {
      /* max-width: 590px; */
    }
  }

  .customer__div {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
    max-width: 314px;
    max-height: 320px;
  }
</style>
