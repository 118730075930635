<template>
  <section class="tw-pb-8" v-if="tableRows && tableRows.length > 0">
    <div class="table__div tw-mb-16">
      <table id="transaction-table">
        <thead class="header">
          <tr class="header-row">
            <th
              class="header-data tw-font-normal tw-py-6"
              ref="tableHead"
              :style="gridStyling"
            >
              <div
                class="tw-justify-self-start tw-text-sm tw-text-black-light"
                :class="{
                  'tw-mx-auto': header === 'Action',
                  'tw-hidden': header === 'misc',
                }"
                v-for="(header, index) in tableHeaders"
                :key="'header' + index"
              >
                {{ header }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr class="body-row">
            <td class="body-data" ref="tableRow">
              <div
                class="data tw-py-6 tw-border-t tw-border-gray-light hover:tw-bg-gray-lightest"
                v-for="(details, index) in tableRows"
                :key="'row' + index"
                :style="gridStyling"
              >
                <span class="tw-justify-self-end">
                  <img
                    :src="details.image"
                    class="tw-ml-2 tw-w-12 tw-h-12 tw-rounded-full tw-object-cover"
                    alt="in arrow"
                    loading="lazy"
                  />
                </span>
                <div
                  v-for="(detail, key, index) in details"
                  class="tw-self-center"
                  :class="{
                    'tw-hidden': key === 'image',
                  }"
                  :key="'column' + index"
                >
                  <p
                    v-html="detail"
                    :class="{
                      [String(detail).toLowerCase()]:
                        availableStatuses.includes(detail),
                    }"
                    class="tw-text-sm"
                  ></p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <!-- <ViewTransactionModal
          v-if="showViewTransactionModal"
          class="tw-z-20"
          @close="showViewTransactionModal = !showViewTransactionModal"
        >
          <template v-slot:content>
            <div class="header">
              <h3 class="tw-text-xl tw-text-black-dark">Transaction Details</h3>
              <p class="tw-text-gray tw-text-lg tw-mt-2">
                {{ transactionRef }}
              </p>
            </div>
            <div class="tw-mt-16">
              <p
                class="tw-flex tw-justify-between tw-text-secondary tw-border-b tw-pb-3.5"
              ></p>
            </div>
            <div class="tw-border-b tw-pb-3.5 tw-my-4">
              <div class="tw-flex tw-justify-between">
                <p class="tw-text-black-light tw-text-xs">Amount</p>
                <p class="tw-text-black-light tw-text-xs">Type</p>
              </div>
              <div class="tw-flex tw-justify-between">
                <p>{{ amount }}</p>
                <p>{{ type }}</p>
              </div>
            </div>
            <div class="tw-pb-10">
              <div class="tw-flex tw-justify-between">
                <p class="tw-text-black-light tw-text-xs">Date</p>
                <p class="tw-text-black-light tw-text-xs">Status</p>
              </div>
              <div class="tw-flex tw-justify-between">
                <p>{{ date }}</p>
                <p
                  :class="{
                    'tw-text-success': status === 'Completed',
                    'tw-text-warning': status === 'Pending',
                    'tw-text-error': status === 'Failed',
                  }"
                >
                  {{ status }}
                </p>
              </div>
            </div>
          </template>
        </ViewTransactionModal> -->
      </table>
    </div>
    <div class="tw-flex tw-justify-end lg:tw-mr-8">
      <Pagination
        :totalPage="totalPage"
        :currentPage="currentPage"
        :maxVisible="9"
        @pageChange="gotoPage"
      />
    </div>
  </section>
</template>

<script>
  import ViewTransactionModal from "@/components/general/MediumModal";
  import Pagination from "@/components/navigation/Pagination";
  import { formatAmountToDollar } from "@/utils/helpers.js";
  import { mapActions } from "vuex";
  import useValidate from "@vuelidate/core";
  import { required, maxValue } from "@vuelidate/validators";
  export default {
    name: "CustomerTransactionTable",

    components: {
      Pagination,
      ViewTransactionModal,
    },

    props: {
      tableHeaders: { type: Array, default: () => [], required: true },
      tableRows: { type: Array, default: () => [], required: true },
      columnNumber: { type: Number, default: () => 0, required: true },
      columnWidth: { type: Number, default: () => 0, required: true },
      totalPage: { type: Number, default: () => 0, required: true },
      currentPage: { type: Number, default: () => 0, required: true },
    },

    emits: ["go-to-page", "refund-request"],

    data() {
      return {
        rows: [],
        v$: useValidate(),
        type: "",
        loading: false,
        colunmSize: "",
        showViewTransactionModal: false,
        transactionRef: "",
        discription: "",
        image: "",
        amount: "",
        type: "",
        date: "",
        status: "",
        showActions: false,
        activeRow: null,
        maxRefundAmount: "",
        refundTransactionId: 0,
        showRefundModal: false,
        refundAmount: "",
        requestMaxAMount: false,
        availableStatuses: ["Completed", "Failed", "Pending"],
      };
    },
    validations() {
      return {
        refundAmount: {
          required,
          maxValue: maxValue(this.maxRefundAmount),
        },
      };
    },
    computed: {
      gridStyling() {
        return {
          "grid-template-columns": `${80}px ${180}px ${100}px ${100}px ${100}px ${180}px ${100}px repeat(${
            this.columnNumber
          }, ${this.columnWidth}px)`,
        };
      },
    },
    watch: {
      refundAmount(val) {
        if (val !== this.maxRefundAmount) {
          this.requestMaxAMount = false;
        }
      },
      requestMaxAMount(val) {
        if (val) {
          this.refundAmount = this.maxRefundAmount;
        }
      },
    },

    methods: {
      ...mapActions("investorModule", ["getRefund"]),
      ...mapActions(["showToast"]),
      formatAmountToDollar,
      viewTransaction(details) {
        this.transactionRef = details.reference.split(">")[1].split("<")[0];
        this.description = details.details.split(">")[1].split("<")[0];
        this.amount = details.amount;
        const misc = details.misc.split("/");
        this.type = misc[2];
        this.date = details.details.split(">")[4].split("<")[0];
        this.status = details.status;
        this.showViewTransactionModal = true;
      },
      setAction(index) {
        this.activeRow = index;
        this.showActions = true;
      },

      gotoPage(page) {
        this.$emit("go-to-page", page);
      },
      requestRefund(details) {
        const misc = details.misc.split("/");
        const transactionId = misc[1];
        this.maxRefundAmount = parseFloat(
          details.amount.split("$")[1].replace(/,/g, "")
        );
        this.refundTransactionId = transactionId;
        this.showActions = false;
        this.showRefundModal = true;
      },
      validateForm() {
        this.v$.refundAmount.$touch();
        this.v$.$validate();
        if (this.v$.$errors.length === 0) {
          this.processRefund();
        }
      },
      async processRefund() {
        const object = {
          amount: this.refundAmount,
          id: this.refundTransactionId,
        };
        this.loading = true;
        try {
          const response = await this.getRefund(object);
          const { message } = response.data;
          this.showToast({
            description: message,
            display: true,
            type: "success",
          });
          this.loading = false;
        } catch (error) {
          this.loading = false;
          return error;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table__div {
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  #transaction-table {
    width: max-content;
    overflow-x: scroll;
    @media (min-width: 1280px) {
      width: 100%;
    }
    @include fadeIn;
    .header {
      .header-row {
        border-bottom: 1px solid #f0f4fd;
        .header-data {
          display: grid;
          column-gap: 2rem;
          @media (min-width: 1280px) {
            grid-auto-flow: row;
          }
        }
      }
    }
    .table-body {
      .body-row {
        border-bottom: 1px solid #f0f4fd;
        .body-data {
          .data {
            display: grid;
            column-gap: 2rem;
            font-size: 0.7rem;
            .body-button {
              border-radius: 50%;
              border: 0;
              &:hover {
                background-color: transparent;
                box-shadow: 2px 2px 7px 2px #f0f4fd;
              }
            }
          }
        }
      }
    }
    // Added with javascript
    .completed,
    .pending,
    .failed {
      text-align: left;
      padding: 0.5rem;
      border-radius: 5px;
    }
    .completed {
      color: $success;
      background-color: $success-light;
      max-width: 5.4rem;
    }
    .pending {
      color: $warning;
      background-color: $warning-light;
      max-width: 4.2rem;
    }
    .failed {
      color: $error;
      background-color: $error-light;
      max-width: 3.6rem;
    }

    .action {
      &__div {
        background: #ffffff;
        box-shadow: 0px 2px 30px rgba(19, 38, 83, 0.06);
        border-radius: 4px;
        width: 160px;
        position: absolute;
        right: 50px;
        top: 30px;
        z-index: 2;
        height: max-content;
        box-sizing: border-box;
      }
      &__list {
        height: 56px;
        display: flex;
        cursor: pointer;
        align-items: center;
        width: 100%;
      }
    }
  }

  .refund {
    &__form {
      padding: 18px 30px 40px 30px;
      border-top: 1px solid #f0f4fd;
    }
    &__input {
      border: 1px solid #e1e6f0;
      transition: all 300ms ease-in-out;
      height: 50px;
      &--error {
        border: 1px solid #db4343;
      }
    }
  }
</style>
