import { onBeforeUnmount, ref } from "vue";

export function useFilter() {
  const showFilterOptions = ref(false);
  const timeout = ref(null);

  const startTimeout = () => {
    if (showFilterOptions.value) {
      timeout.value = setTimeout(() => {
        showFilterOptions.value = false;
      }, 3000);
    }
  };
  const deleteTimeout = () => {
    clearTimeout(timeout.value);
    timeout.value = null;
  };
  onBeforeUnmount(() => {
    deleteTimeout();
  });

  return { showFilterOptions, timeout, deleteTimeout, startTimeout };
}
