<template>
  <section
    class="all-transactions tw-h-screen tw-overflow-y-scroll tw-pb-6 tw-px-4 lg:tw-px-8"
  >
    <div
      class="md:tw-flex md:tw-justify-between tw-items-center tw-mb-8 tw-border-b tw-pb-8 md:tw-border-gray-light3"
    >
      <div>
        <h1 class="tw-text-secondary tw-text-lg tw-font-bold">
          All Transactions
        </h1>
        <p class="tw-text-black-light">
          A total of {{ convertToLocale(totalTransactions) }}
          {{ totalTransactions > 1 ? "transactions" : "transaction" }} have
          occurred.
        </p>
      </div>
      <div class="tw-mt-8 md:tw-mt-0">
        <button
          @click="$router.go(-1)"
          class="tw-h-12 tw-text-sm tw-text-gray-dark2 tw-bg-black-light3 tw-rounded tw-w-44 tw-font-normal tw-flex tw-items-center tw-justify-center tw-gap-x-3"
        >
          <img
            src="https://res.cloudinary.com/djalafcj9/image/upload/v1649664940/getequityV2/Company-dealroom/Back-button_moomg1.svg"
            alt="back icon"
          />
          Go Back
        </button>
      </div>
    </div>
    <div
      class="tw-mb-5 md:tw-mb-0 tw-flex tw-gap-x-0 tw-items-center"
      :class="{
        ' md:tw-w-full': currentWidth >= 768 && (showFilterOptions || true),
      }"
    >
      <Search
        type="reference"
        :showSearchField="true"
        placeholder="Search Transaction"
        @search-input="filterTransactions"
      />
      <Filter
        title="Transactions"
        :filterOptions="filterOptions"
        @filter-click="showFilterOptions = !showFilterOptions"
        :showFilterOptions="showFilterOptions"
        @filter-selected="filterTransactions"
        class="md:tw-right-[-2.688rem]"
        @mouseenter="deleteTimeout"
        @mouseleave="startTimeout"
      />
    </div>
    <div class="tw-bg-white tw-mb-8">
      <div class="table__div tw-mb-8" v-if="tableRows && tableRows.length > 0">
        <TransactionTable
          :tableRows="tableRows"
          :tableHeaders="tableHeaders"
          :columnNumber="1"
          :totalPage="transactionsPages"
          :currentPage="transactionPage"
          :column-width="150"
          @go-to-page="fetchTransactions"
        />
      </div>
      <EmptyState
        v-if="
          !transactions ||
            (transactions && transactions.length === 0) ||
            !transactionsArray ||
            transactionsArray.length === 0
        "
      >
        <template v-slot:message>
          <h3
            v-if="
              !transactionsArray ||
                (transactionsArray.length === 0 && transactions.length !== 0)
            "
            class="tw-text-sm tw-text-secondary tw-mt-5"
          >
            No result found!
          </h3>
          <h3 v-else class="tw-text-sm tw-text-secondary tw-mt-5 tw-max-w-sm">
            You do not have any transaction — yet
          </h3>
          <p
            class="message tw-text-center tw-text-purple-light tw-my-3 tw-w-96 tw-mx-auto tw-mb-10"
            v-if="
              !transactionsArray ||
                (transactionsArray.length === 0 && transactions.length !== 0)
            "
          >
            Perhaps you entered the wrong search term, or that transaction does
            not exist.
          </p>
          <p
            v-else
            class="message tw-text-center tw-my-3 tw-text-purple-light tw-mb-10 tw-w-96"
          >
            Your transactions will show up here when you fund, withdraw or
            purchase tokens.
          </p>
        </template>
      </EmptyState>
    </div>
  </section>
</template>

<script>
  import Search from "@/components/general/Search";
  import search from "@/mixins/search";
  import Filter from "@/components/general/Filter";
  import TransactionTable from "@/components/tables/TransactionTable";
  import EmptyState from "@/components/notification/EmptyState";
  import { useFilter } from "@/composables/filter";
  import {
    formatISOTime,
    formatISODate,
    truncate,
    removeUnderscore,
    formatAmountToDollar,
    convertToLocale,
  } from "@/utils/helpers.js";
  import { mapActions, mapGetters } from "vuex";

  const { startTimeout, deleteTimeout, showFilterOptions } = useFilter();

  export default {
    name: "AllTransactions",
    components: {
      EmptyState,
      Search,
      TransactionTable,
      Filter,
    },
    data() {
      return {
        searchValue: "",
        tableHeaders: ["", "Details", "Reference", "Amount", "Price", "Status"],
        filterOptions: ["Completed", "Pending", "Failed"],
        transactionsArray: [],
        showFilterOptions,
      };
    },
    mixins: [search],
    created() {
      this.fetchTransactions(1);
    },
    mounted() {
      this.transactionsArray = this.transactions;
    },
    watch: {
      transactions(value) {
        this.transactionsArray = value;
      },
    },
    computed: {
      ...mapGetters("ApiClient", [
        "transactions",
        "transactionsPages",
        "transactionPage",
        "totalTransactions",
      ]),
      currentWidth() {
        return window.screen.width;
      },
      tableRows() {
        return this.transactionsArray?.map((transaction) => {
          let type = removeUnderscore(transaction.type);
          switch (type) {
            case "Debit Sell Wallet":
              type = "Debit Wallet";
              break;

            case "Fund Sell Wallet":
              type = "Fund Wallet";
              break;
            default:
              break;
          }

          let name = `${transaction.token.name}`;
          switch (name) {
            case "GetEquity USD Token":
              name = "USD";
              break;
            default:
              break;
          }
          let volume = transaction.volume;
          let details;
          if (type === "Plan") {
            details = truncate(`${type}: ${transaction.price} ${name}`, 30);
          } else {
            details = truncate(`${type}: ${volume} ${name}`, 30);
          }
          let amount;
          if (transaction.price) {
            amount = transaction.price * transaction.volume;
          } else {
            amount = transaction.token?.price.buy * transaction.volume;
          }
          return {
            details: `<span class="tw-font-bold">${details}</span> <br />
          <span class="tw-text-black-light tw-text-xs">${formatISODate(
            transaction.createdAt
          )}  ${formatISOTime(transaction.createdAt)}</span>`,
            reference:
              type === "Fee" ||
              type === "Debit Wallet" ||
              type === "Send" ||
              type === "Withdraw" ||
              type === "Sell" ||
              type === "Plan"
                ? `<span class="tw-text-xs tw-mb-1">${truncate(
                    transaction.reference,
                    25
                  )}</span> <br />
          <span class="tw-text-xs tw-text-error">Debit</span>`
                : `<span class="tw-text-xs tw-mb-1">${truncate(
                    transaction.reference,
                    25
                  )}</span> <br />
          <span class="tw-text-xs tw-text-success">Credit</span>`,
            amount: formatAmountToDollar(amount, 2, "USD"),
            price: transaction.price
              ? formatAmountToDollar(transaction.price, 2, "USD")
              : "N/A",
            type: `<span class="tw-hidden">${type}</span>`,
            status: transaction.status,
          };
        });
      },
    },
    methods: {
      ...mapActions("ApiClient", ["getTransactions"]),
      convertToLocale,
      startTimeout,
      deleteTimeout,
      async fetchTransactions(page) {
        let query = { page, limit: 10 };
        try {
          await this.getTransactions(query);
        } catch (error) {
          return error;
        }
      },
      filterTransactions({ value, type }) {
        if (value && value !== "All") {
          const transactions = this.transactions;
          const filterTransactions = transactions.filter((txn) => {
            if (type === "reference") {
              return txn[type].toLowerCase().includes(value);
            } else if (type === "updatedAt") {
              return txn[type].includes(value.toISOString().split("T")[0]);
            } else {
              return txn[type] === value;
            }
          });
          this.transactionsArray = filterTransactions;
        } else {
          this.transactionsArray = this.transactions;
        }
      },
    },
  };
</script>
<style lang="scss" scoped></style>
